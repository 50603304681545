import type { PreLoadableDataObjectExtension } from './types.ts';

export type { ILayoutColumnProperties, ILayoutModuleOptions, ILayoutRecord } from './DataObject.Layout.ts';
export type { DataObjectEvents } from './DataObject.ts';
export type {
    IDataHandler, RequestOperation, IRetrieveOptions, ICreateOptions, IUpdateOptions,
    IDestroyOptions, IDistinctOptions, IRowCountOptions
} from './DataObject.DataHandler.ts';
export type { DataObjectExtensionCallback, DataObjectExtensionMethods } from './DataObject.Extension.ts';
export type * from './types.ts';
export type * from './store.ts';

export { default as DataObject } from './DataObject.ts';
export { default as DataHandler } from './DataObject.DataHandler.ts';
export { default as DynamicLoading } from './DataObject.DynamicLoading.ts';
export { default as Fields } from './DataObject.Fields.ts';
export { default as Item } from './DataObject.Item.ts';

export { default as Layout, LayoutModule, LayoutType } from './DataObject.Layout.ts';
export { SharingCapabilitiesChecker, procSetLayoutAsHidden, procUnsetLayoutAsHidden } from './DataObject.Layout.UpdatesRetriever.ts';

export { default as MasterDetails } from './DataObject.MasterDetails.ts';
export { default as RecordSource } from './DataObject.RecordSource.ts';
export { default as SelectionControl } from './DataObject.SelectionControl.ts';
export { default as State } from './DataObject.State.ts';
export { default as Storage } from './DataObject.Storage.ts';

export { default as DataObjectExtension } from './DataObject.Extension.ts';

export { createDataObject, dataObjectStore, deleteDataObject, getDataObjectById, getDataObjectConfigById, getOrCreateDataObject, initDataObjectFromConfig, resetDataObject } from './store.ts';

export { registerDefinitionProcParameters } from './helpers.ts';

/**
 * Initialize DataObject extension/extensions. Should be used before `createApp`.  
 * 
 * @param pExtension - Extension name or array of names to initialzie
 * @example
 * initializeDataObjectExtension('subSelect')
 *  .then(() => import('./App'))
 *  .then((m) => createApp(m.default))
 *  .then((app) => {
 *      app.use(CoreComponents);
 *      app.mount('#app');
 *  });
 */
export async function initializeDataObjectExtension(pExtension: PreLoadableDataObjectExtension | PreLoadableDataObjectExtension[]) {
    const getInitPromise = (pExt: PreLoadableDataObjectExtension) => {
        switch (pExtension) {
            case 'subSelect':
                return import('./extensions.SubSelect.ts');
            case 'nodeData':
                return import('o365-nodedata');
            default:
                return Promise.resolve(`Can't preload ${pExt}`);
        }
    };

    if (Array.isArray(pExtension)) {
        const initPromises = pExtension.map((ext) => getInitPromise(ext));
        await Promise.all(initPromises);
    } else {
        await getInitPromise(pExtension);
    }
}